import { createVNode as _createVNode, mergeProps as _mergeProps } from "vue";
import { defineComponent } from "vue";
import { VBtn } from "vuetify/components";
import "./FButton.css";
export const FButton = defineComponent({
  name: "FButton",
  props: {
    icon: {
      type: Boolean,
      default: false
    }
  },
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    const presets = {
      variant: "flat",
      rounded: true,
      ripple: false
    };
    if (props.icon) {
      presets.variant = "text";
    }
    return () => _createVNode(VBtn, _mergeProps({
      "class": "f-button"
    }, presets, props), {
      default: () => [slots.default?.()]
    });
  }
});