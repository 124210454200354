import { mergeProps as _mergeProps, createVNode as _createVNode } from "vue";
import { defineComponent, ref } from "vue";
import { FInput } from "../FInput";
import { FCountryCodeSelect } from "./FCountryCodeSelect";
export const FPhoneInput = defineComponent({
  name: "FPhoneInput",
  props: {
    code: {
      type: String,
      default: ""
    }
  },
  emits: {
    "update:code": value => true
  },
  setup(props, _ref) {
    let {
      slots,
      emit
    } = _ref;
    const dialog = ref(false);
    const presets = {
      type: "number"
    };
    const handleKeydown = e => {
      if (["e", "+", "-", "."].includes(e.key)) {
        e.preventDefault();
      }
    };
    return () => _createVNode(FInput, _mergeProps(presets, {
      "onKeydown": handleKeydown
    }), {
      ...slots,
      "prepend-inner": () => _createVNode(FCountryCodeSelect, {
        "dialog": dialog.value,
        "onUpdate:dialog": $event => dialog.value = $event,
        "code": props.code,
        "onUpdate:code": v => emit("update:code", v)
      }, null)
    });
  }
});