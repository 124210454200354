import { createVNode, nextTick, render, getCurrentInstance } from "vue";
import { FAuthMethodModal } from "../components/FAuthMethodModal";
export function useAuth() {
  const instance = getCurrentInstance();
  return instance.appContext.config.globalProperties.$uikit.auth;
}
function install(app, globalOptions) {
  let instance = null;
  const show = options => {
    if (instance) {
      instance.component.exposed.close();
    }
    nextTick(() => {
      const mergedOptions = {
        ...globalOptions,
        ...options
      };
      const appendTo = document.querySelector(mergedOptions.container || "[data-v-app]");
      const container = document.createElement("div");
      const vnode = createVNode(FAuthMethodModal, {
        ...mergedOptions,
        attach: container,
        onAuth: options.handleAuth,
        onError: options.handleError,
        onDestroy: () => {
          render(null, container);
          instance = null;
          appendTo?.removeChild(container);
        }
      });
      vnode.appContext = app._context;
      render(vnode, container);
      appendTo?.appendChild(container);
      instance = vnode;
      instance.component.exposed.show();
    });
  };
  const auth = {
    show
  };
  const properties = app.config.globalProperties;
  properties.$uikit = properties.$uikit || {};
  properties.$uikit.auth = auth;
}
export function Auth() {}
Auth.install = install;