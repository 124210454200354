import { mergeProps as _mergeProps, createVNode as _createVNode } from "vue";
import { defineComponent, computed, ref } from "vue";
import { FTooltip } from "../FTooltip";
import { FModal } from "../FModal";
import { FHintActivator } from "./FHintActivator";
import { FHintLink } from "./FHintLink";
import { useDisplay, useLocale } from "vuetify";
import "./FHint.css";
export const FHint = defineComponent({
  name: "FHint",
  props: {
    hint: {
      type: String,
      default: ""
    },
    href: {
      type: String,
      default: ""
    }
  },
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    const {
      smAndUp
    } = useDisplay();
    const {
      t
    } = useLocale();
    const dialog = ref(false);
    const meta = computed(() => {
      const isTooltip = smAndUp.value;
      return {
        isTooltip,
        wrapper: isTooltip ? FTooltip : FModal,
        presets: isTooltip ? {
          contentClass: "f-hint__content",
          text: props.hint
        } : {
          title: t("$vuetify.uikit.details")
        }
      };
    });
    const content = () => meta.value.isTooltip ? props.href && _createVNode(FHintLink, {
      "href": props.href
    }, null) : _createVNode("div", {
      "class": "f-hint__modal-content"
    }, [_createVNode("span", {
      "innerHTML": props.hint
    }, null), props.href && _createVNode(FHintLink, {
      "href": props.href
    }, null)]);
    return () => _createVNode(meta.value.wrapper, _mergeProps({
      "modelValue": dialog.value,
      "onUpdate:modelValue": $event => dialog.value = $event
    }, meta.value.presets), {
      activator: slots.activator ? slots.activator : params => _createVNode(FHintActivator, params.props, null),
      default: () => content()
    });
  }
});