import { createVNode as _createVNode } from "vue";
export var IconClose = function IconClose() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "24",
    "height": "24",
    "viewBox": "0 0 24 24",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "d": "M18 6L6 18M6 6L18 18",
    "stroke": "currentColor",
    "stroke-width": "2",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null)]);
};