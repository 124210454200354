import { createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import { defineComponent, computed } from "vue";
import { VImg, VIcon } from "vuetify/components";
import { useLocale, useTheme, useDisplay } from "vuetify";
export const FAuthStep1 = defineComponent({
  name: "FAuthStep1",
  inheritAttrs: false,
  props: {
    step: {
      type: Number,
      default: 1
    },
    method: {
      type: String,
      default: ""
    },
    authMethods: {
      type: Array,
      default: () => ["mixin"]
    },
    authMethodState: {
      type: Object,
      default: () => ({})
    }
  },
  emits: {
    close: () => true,
    auth: v => true,
    "update:method": v => true,
    "update:step": v => true
  },
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const {
      t
    } = useLocale();
    const {
      mdAndUp
    } = useDisplay();
    const theme = useTheme();
    const grey = theme.current.value.colors.greyscale_3;
    const isDark = computed(() => {
      return theme.name.value === "dark";
    });
    const authMethodsMeta = [{
      needNextStep: !props.authMethodState["metamask"],
      value: "metamask",
      title: "MetaMask",
      bg: isDark.value ? grey : "#FFEEDD",
      logo: "https://static.fox.one/image/logo_metamask@40x40.png"
    }, {
      needNextStep: true,
      value: "mixin",
      title: "Mixin",
      bg: isDark.value ? grey : "#EBF8FF",
      logo: "https://static.fox.one/image/logo_mixin@40x40.png"
    }, {
      needNextStep: !props.authMethodState["fennec"],
      value: "fennec",
      title: "Fennec",
      bg: isDark.value ? grey : "#E6E7FD",
      logo: "https://static.fox.one/image/logo_fennec@40x40.png"
    }, {
      needNextStep: false,
      value: "walletconnect",
      title: "WalletConnect",
      bg: isDark.value ? grey : "#f5f5f5",
      logo: isDark.value ? "https://static.fox.one/image/logo_walletconnect_dark@40x40.png" : "https://static.fox.one/image/logo_walletconnect@40x40.png"
    }, {
      needNextStep: !props.authMethodState["onekey"],
      value: "onekey",
      title: "OneKey",
      bg: isDark.value ? grey : "#E9FEE6",
      logo: "https://static.fox.one/image/logo_onekey@40x40.png"
    }];
    const getAuthMethodsMeta = () => props.authMethods.map(name => authMethodsMeta.find(x => x.value === name)).filter(v => !!v);
    const handleAuth = item => {
      if (!item.needNextStep) {
        emit("close");
        emit("auth", {
          type: item.value
        });
      } else {
        emit("update:method", item.value);
        emit("update:step", 2);
      }
    };
    return () => _createVNode("div", {
      "class": "f-auth-step1"
    }, [_createVNode("div", {
      "class": ["f-auth-methods", {
        "f-auth-methods--mobile": !mdAndUp.value
      }]
    }, [getAuthMethodsMeta().map(item => _createVNode("div", {
      "key": item.value,
      "style": {
        backgroundColor: item.bg
      },
      "class": "f-auth-method",
      "onClick": () => handleAuth(item)
    }, [_createVNode("span", {
      "class": "f-auth-method__logo"
    }, [_createVNode(VImg, {
      "width": "40",
      "height": "40",
      "src": item.logo
    }, null)]), _createVNode("span", {
      "class": "f-auth-method__label"
    }, [item.title])]))]), _createVNode("div", {
      "class": "f-auth-hint"
    }, [_createVNode(VIcon, {
      "size": "16",
      "color": "warning",
      "class": "f-auth-hint__horn"
    }, {
      default: () => [_createTextVNode("$horn")]
    }), _createVNode("span", {
      "innerHTML": t("$vuetify.uikit.gas_fee_hint")
    }, null)])]);
  }
});