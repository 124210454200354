export default {
  talkee: {
    sort_newest: "最新",
    sort_oldest: "最舊",
    sort_score: "評分",
    logout: "登出",
    login: "登入",
    submit: "提交",
    comment_placeholder: "發表評論",
    reply: "回覆",
    error_hint: "出現錯誤",
    load_more: "顯示更多",
    no_more_comments: "沒有更多評論",
    view_in_explorer: "在區塊瀏覽器中查看",
    user_info: "使用者資訊",
    airdrop: "發送空投",
    reward: "發送小費",
    select_asset: "選擇資產",
    memo: "備註",
    select_strategy: "策略",
    input_topn: "空投數量",
    start_chat: "開始聊天",
    input_integer: "輸入 N",
    input_amount: "總金額",
    asset_required: "必須選擇資產",
    amount_invalid: "金額無效",
    n_required: "必須指定空投數量",
    amount_need_integer: "金額必須是整數",
    reward_details: "空投詳情",
    airdrop_details: "空投詳情",
    chat_room: "聊天室",
    please_login: "請先登入",
    top_n: "前 N 名",
    avg: "平均分配",
    top_n_hint: "只有前 N 名最高评分的評論會分享空投",
    avg_hint: "每個評論都會分享空投",
    total_received: "總共收到的金額",
    details: "詳細資訊",
    send_tips: "發送小費",
    send_airdrop: "發送空投",
  },
};
