import { IconChevronLeft, IconChevronRight, IconClose, IconCloseCircle, IconChevronDown, IconArrowSquareUpRightFill, IconHelpCircleFill, IconAlert, IconSearch, IconCheck, IconArrowDown, FIconImport4PBold, FIconArrowRight4P, FIconHorn4P, FIconLink4P } from "@foxone/icons";
export const icons = {
  back: IconChevronLeft,
  next: IconChevronRight,
  close: IconClose,
  clear: IconCloseCircle,
  expand: IconChevronDown,
  swap: IconArrowDown,
  fill: IconArrowSquareUpRightFill,
  help: IconHelpCircleFill,
  warning: IconAlert,
  search: IconSearch,
  check: IconCheck,
  install: FIconImport4PBold,
  link: FIconLink4P,
  alert: IconAlert,
  horn: FIconHorn4P,
  arrow_right: FIconArrowRight4P
};