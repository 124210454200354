import { mergeProps as _mergeProps, createVNode as _createVNode } from "vue";
import { defineComponent, ref } from "vue";
import { VTextField } from "vuetify/components";
import { numberInput } from "../../utils/number";
import "./FInput.css";
export const FInput = defineComponent({
  name: "FInput",
  props: {
    modelValue: {
      type: String,
      default: ""
    },
    precision: {
      type: [Number, String]
    },
    hideDefaultMessages: Boolean
  },
  emits: {
    "update:modelValue": value => true
  },
  setup(props, _ref) {
    let {
      attrs,
      emit,
      slots
    } = _ref;
    const inputRef = ref();
    const focused = ref(false);
    const preset = {
      density: "comfortable"
    };
    const handleChange = value => {
      if (attrs.type === "number" && props.precision) {
        emit("update:modelValue", numberInput(value, props.precision));
        inputRef?.value?.$forceUpdate();
        return;
      }
      emit("update:modelValue", value);
    };
    const handleKeydown = e => {
      if (attrs.type === "number") {
        if (["e", "+", "-"].includes(e.key)) {
          e.preventDefault();
        }
      }
    };
    return () => _createVNode(VTextField, _mergeProps({
      "ref": inputRef,
      "focused": focused.value,
      "onUpdate:focused": $event => focused.value = $event,
      "class": ["f-input", {
        "f-input--hide-default-messages": props.hideDefaultMessages
      }, {
        "f-input--focused": focused.value
      }],
      "modelValue": props.modelValue,
      "onUpdate:modelValue": handleChange,
      "onKeydown": handleKeydown
    }, preset), {
      ...slots,
      details: slots.tools ? slotProps => {
        return _createVNode("div", {
          "class": "f-input__tools"
        }, [slots.tools?.(slotProps)]);
      } : slots.details
    });
  }
});