import { mergeProps as _mergeProps, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
import { computed, defineComponent } from "vue";
import { VDialog, VCard, VCardTitle, VCardText, VIcon, VMenu } from "vuetify/components";
import { FButton } from "../FButton";
import { useDisplay } from "vuetify";
import "./FModal.css";
export const FModal = defineComponent({
  name: "FModal",
  props: {
    hideClose: Boolean,
    title: {
      type: [String, Object],
      default: ""
    },
    desktop: {
      type: String,
      default: "dialog"
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    "update:modelValue": value => true
  },
  setup(props, _ref) {
    let {
      slots,
      emit,
      attrs
    } = _ref;
    const {
      smAndDown
    } = useDisplay();
    const meta = computed(() => {
      const isMenu = !smAndDown.value && props.desktop === "menu";
      return {
        isMenu,
        hasTitle: !isMenu && (props.title || slots.title),
        hasClose: props.hideClose || attrs.persistent || isMenu,
        wrapper: isMenu ? VMenu : VDialog,
        presets: smAndDown.value ? {
          transition: "dialog-bottom-transition"
        } : {
          maxWidth: 600
        }
      };
    });
    const classes = computed(() => {
      return {
        "f-modal": true,
        "f-modal-menu": meta.value.isMenu,
        "f-bottom-sheet": smAndDown.value
      };
    });
    const content = () => {
      return _createVNode(VCard, {
        "class": "f-modal__content"
      }, {
        default: () => [!meta.value.hasClose && _createVNode(FButton, {
          "icon": true,
          "size": "32",
          "class": "f-modal__close",
          "onClick": e => {
            emit("update:modelValue", false);
          }
        }, {
          default: () => [_createVNode(VIcon, null, {
            default: () => [_createTextVNode("$close")]
          })]
        }), meta.value.hasTitle && _createVNode(VCardTitle, {
          "class": "f-modal__title"
        }, {
          default: () => [props.title || slots.title?.()]
        }), _createVNode(VCardText, {
          "class": "f-modal__text"
        }, {
          default: () => [slots.default?.()]
        })]
      });
    };
    return () => _createVNode(meta.value.wrapper, _mergeProps({
      "modelValue": props.modelValue
    }, meta.value.presets, {
      "class": classes.value,
      "onUpdate:modelValue": v => emit("update:modelValue", v)
    }), {
      ...slots,
      default: () => content()
    });
  }
});