import { mdi } from "vuetify/iconsets/mdi";
import { en, ja, zhHans, zhHant } from "vuetify/locale";
import { icons } from "./icons";
import { mergeDeep } from "vuetify/lib/util/helpers.mjs";
import { dark, light } from "./theme";
import { locales } from "../locales";
export function usePresets(options) {
  const persets = {
    icons: {
      defaultSet: "mdi",
      sets: {
        mdi
      },
      aliases: {
        ...icons
      }
    },
    theme: {
      themes: {
        dark,
        light
      }
    },
    locale: {
      messages: mergeDeep({
        en,
        ja,
        zhHans,
        zhHant
      }, locales)
    }
  };
  return mergeDeep(persets, options);
}