import { createVNode as _createVNode } from "vue";
import { defineComponent } from "vue";
import { VTooltip } from "vuetify/components";
import "./FTooltip.css";
export const FTooltip = defineComponent({
  name: "FTooltip",
  props: {
    text: {
      type: String,
      default: ""
    }
  },
  emits: {
    "update:modelValue": v => true
  },
  setup(props, _ref) {
    let {
      slots,
      attrs,
      emit
    } = _ref;
    const content = () => {
      return _createVNode("div", {
        "class": "f-tooltip__tip"
      }, [_createVNode("span", {
        "innerHTML": props.text
      }, null), _createVNode("span", null, [slots.default?.()])]);
    };
    return () => _createVNode(VTooltip, {
      "location": "top center",
      "class": "f-tooltip",
      "onClick:outside": () => emit("update:modelValue", false),
      "onUpdate:modelValue": v => emit("update:modelValue", v)
    }, {
      ...slots,
      default: () => content()
    });
  }
});