import { createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
import { VForm, VIcon, VProgressLinear } from "vuetify/components";
import { defineComponent } from "vue";
import { FButton } from "../FButton";
import "./FAssetSwapForm.css";
export const FAssetSwapForm = defineComponent({
  name: "FAssetSwapForm",
  props: {
    loading: Boolean
  },
  emits: {
    switch: () => true
  },
  setup(props, _ref) {
    let {
      slots,
      emit
    } = _ref;
    return () => _createVNode(VForm, {
      "class": "f-asset-swap-form"
    }, {
      default: () => [slots.input?.(), _createVNode("div", {
        "class": "f-asset-swap-form__divider"
      }, [_createVNode(FButton, {
        "icon": true,
        "size": "32",
        "class": "f-asset-swap-form__swap",
        "onClick": () => emit("switch")
      }, {
        default: () => [_createVNode(VIcon, {
          "size": "18"
        }, {
          default: () => [_createTextVNode("$swap")]
        })]
      })]), slots.output?.(), props.loading && _createVNode(VProgressLinear, {
        "color": "primary",
        "height": 2,
        "indeterminate": true
      }, null)]
    });
  }
});