import { createVNode as _createVNode, mergeProps as _mergeProps } from "vue";
import { defineComponent } from "vue";
import { VProgressCircular } from "vuetify/components";
import "./FLoading.css";
export const FLoading = defineComponent({
  name: "FLoading",
  props: {
    text: String,
    processProps: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    return () => _createVNode("div", {
      "class": "f-loading__content"
    }, [_createVNode(VProgressCircular, _mergeProps({
      "width": 3,
      "indeterminate": true
    }, props.processProps), null), _createVNode("span", {
      "class": "f-loading__text"
    }, [slots.text?.() ?? props.text])]);
  }
});