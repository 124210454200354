import { createVNode as _createVNode, resolveDirective as _resolveDirective } from "vue";
import { defineComponent } from "vue";
import { VListItem } from "vuetify/components";
import "./FListItem.css";
export const FListItem = defineComponent({
  name: "FListItem",
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    return () => _createVNode(VListItem, {
      "ripple": false,
      "class": "f-list-item"
    }, slots);
  }
});