import { createTextVNode as _createTextVNode, createVNode as _createVNode, mergeProps as _mergeProps } from "vue";
import { defineComponent, computed } from "vue";
import { VIcon } from "vuetify/components";
import { FButton } from "../FButton";
import { FAuthFennecInstall } from "./FAuthFennecInstall";
import { FAuthMetamaskInstall } from "./FAuthMetamaskInstall";
import { FAuthMixinMessenger } from "./FAuthMixinMessenger";
import { FAuthOnekeyInstall } from "./FAuthOnekeyInstall";
export const FAuthStep2 = defineComponent({
  name: "FAuthStep2",
  inheritAttrs: false,
  props: {
    method: {
      type: String,
      default: ""
    },
    clientId: {
      type: String,
      default: ""
    },
    scope: {
      type: String,
      default: ""
    },
    pkce: {
      type: Boolean,
      default: false
    },
    isFiresbox: {
      type: Boolean,
      default: false
    },
    hosts: {
      type: Array,
      default: () => []
    }
  },
  emits: {
    "update:step": v => true,
    auth: v => true,
    error: v => true,
    close: () => true
  },
  setup(props, _ref) {
    let {
      emit,
      attrs
    } = _ref;
    const getComponent = () => {
      switch (props.method) {
        case "fennec":
          return FAuthFennecInstall;
        case "metamask":
          return FAuthMetamaskInstall;
        case "onekey":
          return FAuthOnekeyInstall;
        default:
          return FAuthMixinMessenger;
      }
    };
    const meta = computed(() => {
      return {
        wrapper: getComponent()
      };
    });
    return () => _createVNode("div", {
      "class": "f-auth-step2"
    }, [_createVNode(meta.value.wrapper, _mergeProps(props, {
      "onAuth": v => emit("auth", v),
      "onError": v => emit("error", v),
      "onClose": () => emit("close")
    }), null), _createVNode(FButton, {
      "icon": true,
      "size": "32",
      "class": "f-auth-step2__back",
      "onClick": () => emit("update:step", 1)
    }, {
      default: () => [_createVNode(VIcon, {
        "color": "black"
      }, {
        default: () => [_createTextVNode("$back")]
      })]
    })]);
  }
});