import { isVNode as _isVNode, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import { defineComponent, ref } from "vue";
import { useLocale } from "vuetify";
import { VIcon, VList, VLazy } from "vuetify/components";
import { FListItem } from "../FListItem";
import { FModal } from "../FModal";
import { FSearchInput } from "../FSearchInput";
import { useCountries } from "../../composables";
import "./FCountryCodeSelect.css";
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
export const FCountryCodeSelect = defineComponent({
  name: "FCountryCodeSelect",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      default: ""
    }
  },
  emits: {
    "update:code": value => true,
    "update:dialog": value => true
  },
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const {
      t
    } = useLocale();
    const filter = ref("");
    const {
      filtered
    } = useCountries(filter);
    const handleSelect = code => {
      emit("update:code", code);
      emit("update:dialog", false);
    };
    return () => _createVNode(FModal, {
      "title": t("$vuetify.uikit.select_phone_code"),
      "modelValue": props.dialog,
      "onUpdate:modelValue": v => emit("update:dialog", v)
    }, {
      activator: _ref2 => {
        let {
          props: {
            onClick
          }
        } = _ref2;
        return _createVNode("div", {
          "class": "f-country-code-select__field",
          "onClick": onClick
        }, [props.code ? _createVNode("span", {
          "class": "f-country-code-select__code"
        }, [props.code || "code"]) : _createVNode(VIcon, null, {
          default: () => [_createTextVNode("$help")]
        }), _createVNode(VIcon, {
          "size": "16"
        }, {
          default: () => [_createTextVNode("$expand")]
        })]);
      },
      default: () => {
        let _slot;
        return _createVNode("div", {
          "class": "f-country-code-select"
        }, [_createVNode("div", {
          "class": "f-country-code-select__search"
        }, [_createVNode(FSearchInput, {
          "modelValue": filter.value,
          "onUpdate:modelValue": $event => filter.value = $event
        }, null)]), _createVNode(VList, {
          "class": "f-country-code-select__list"
        }, _isSlot(_slot = filtered.value.map(country => {
          return _createVNode(VLazy, {
            "minHeight": 48
          }, {
            default: () => [_createVNode(FListItem, {
              "title": country.code + " " + country.name,
              "value": country.code,
              "onClick": () => handleSelect(country.code)
            }, null)]
          });
        })) ? _slot : {
          default: () => [_slot]
        })]);
      }
    });
  }
});