import { createVNode as _createVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective } from "vue";
import { computed, defineComponent } from "vue";
import { VTabs } from "vuetify/components";
import { provideDefaults } from "vuetify/lib/composables/defaults.mjs";
import "./FTabs.css";
export const FTabs = defineComponent({
  name: "FTabs",
  props: {
    narrow: Boolean
  },
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    provideDefaults({
      VTab: {
        ripple: false
      }
    });
    const presets = {
      height: 52
    };
    const classes = computed(() => {
      return ["f-tabs", {
        "f-tabs--narrow": props.narrow
      }];
    });
    return () => _createVNode(VTabs, _mergeProps(presets, {
      "class": classes.value
    }), slots);
  }
});