function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
export default class MixinAPI {
  constructor() {
    _defineProperty(this, "token", "");
    _defineProperty(this, "baseUrl", "https://api.mixin.one");
    _defineProperty(this, "authIntercept", null);
  }
  async request(url) {
    let method = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "GET";
    let options = {
      method,
      headers: {}
    };
    if (this.authIntercept) {
      options = this.authIntercept(options);
    }
    const resp = await fetch(this.baseUrl + url, options);
    const json = await resp.json();
    return json.data;
  }
  getAsset(id) {
    return this.request(`/asset?id=${id}`);
  }
  getAssets() {
    return this.request("/assets");
  }
  codes(code) {
    return this.request(`/codes/${code}`);
  }
  getProfile() {
    return this.request("/me");
  }
}