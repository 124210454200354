import { createVNode as _createVNode, mergeProps as _mergeProps } from "vue";
import { defineComponent } from "vue";
import { VTextarea } from "vuetify/components";
import "./FTextarea.css";
export const FTextarea = defineComponent({
  name: "FTextarea",
  setup() {
    const presets = {
      color: "primary"
    };
    return () => _createVNode(VTextarea, _mergeProps({
      "class": "rounded f-textarea"
    }, presets), null);
  }
});