import { createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import { computed, defineComponent } from "vue";
import { VIcon } from "vuetify/components";
import { useLocale } from "vuetify";
import { FAssetLogo } from "../FAssetLogo";
import "./FAssetSelectField.css";
export const FAssetSelectField = defineComponent({
  name: "FAssetField",
  props: {
    showName: Boolean,
    disabled: Boolean,
    outlined: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    asset: {
      type: Object,
      default: null
    },
    logoBackgroundColor: {
      type: String,
      default: "greyscale_7"
    }
  },
  setup(props) {
    const {
      t
    } = useLocale();
    const classes = computed(() => {
      return {
        "f-asset-field": true,
        "f-asset-field--selectable": props.selectable,
        "f-asset-field--outlined": props.outlined
      };
    });
    return () => _createVNode("div", {
      "class": classes.value
    }, [props.asset ? _createVNode(FAssetLogo, {
      "size": "22",
      "chainSize": "10",
      "asset": props.asset
    }, null) : _createVNode("span", {
      "class": "f-asset-field__placeholder"
    }, [t("$vuetify.uikit.select_asset")]), props.asset && _createVNode("div", {
      "class": "f-asset-field__right"
    }, [_createVNode("div", {
      "class": ["f-asset-field__symbol", {
        "f-asset-field__symbol--sm": (props.asset?.symbol?.length ?? 0) > 8
      }]
    }, [props.asset?.symbol]), props.showName && _createVNode("div", {
      "class": "f-asset-field__name text-greyscale_3"
    }, [props.asset?.name])]), props.selectable && _createVNode(VIcon, {
      "size": "16",
      "class": "f-asset-field__expend"
    }, {
      default: () => [_createTextVNode("$expand")]
    })]);
  }
});