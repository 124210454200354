import { mergeProps as _mergeProps, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import { computed, defineComponent, ref } from "vue";
import { VSnackbar, VIcon, VDivider } from "vuetify/components";
import "./FToast.css";
export const types = ["success", "warning", "error"];
export const FToast = defineComponent({
  name: "FToast",
  props: {
    action: {
      type: Object,
      default: null
    },
    type: String,
    message: String
  },
  emits: {
    destroy: () => true
  },
  setup(props, _ref) {
    let {
      slots,
      expose,
      emit
    } = _ref;
    const visible = ref(false);
    const presets = {
      variant: "flat",
      location: "center center"
    };
    const icon = computed(() => {
      const icons = {
        success: "$check",
        error: "$alert",
        warning: "$alert"
      };
      return icons[props?.type ?? ""];
    });
    const classes = computed(() => {
      return ["f-toast", {
        "f-toast--action": props.action
      }, props.type && `f-toast--${props.type}`];
    });
    const renderMessage = () => _createVNode("div", {
      "class": "f-toast__message"
    }, [icon.value && _createVNode(VIcon, {
      "size": "16",
      "color": props.type,
      "class": "f-toast__icon"
    }, {
      default: () => [icon.value]
    }), _createVNode("span", null, [slots.default?.() || props.message])]);
    const renderActions = () => props.action && _createVNode("div", {
      "class": "f-toast__action",
      "onClick": () => props.action?.callback?.()
    }, [_createVNode(VDivider, {
      "vertical": true,
      "class": "f-toast__action-divider"
    }, null), _createVNode("span", null, [props.action.text]), _createVNode(VIcon, {
      "size": "14"
    }, {
      default: () => [_createTextVNode("$arrow_right")]
    })]);
    const show = () => visible.value = true;
    const close = () => {
      visible.value = false;
      emit("destroy");
    };
    const update = v => v ? show() : close();
    expose({
      show,
      close
    });
    return () => _createVNode(VSnackbar, _mergeProps({
      "modelValue": visible.value,
      "class": classes.value
    }, presets, {
      "onUpdate:modelValue": update
    }), {
      default: renderMessage,
      actions: renderActions
    });
  }
});