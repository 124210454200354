import { mergeProps as _mergeProps, createVNode as _createVNode } from "vue";
import { defineComponent, ref, computed } from "vue";
import { FInput } from "../FInput";
import { FAssetSelect } from "../FAssetSelect";
import "./FAssetAmountInput.css";
export const FAssetAmountInput = defineComponent({
  name: "FAssetAmountInput",
  props: {
    fullfilled: {
      type: Boolean,
      default: false
    },
    amount: {
      type: String,
      default: ""
    },
    asset: {
      type: Object,
      default: null
    },
    assets: {
      type: Array,
      default: () => []
    },
    themeColor: {
      type: String,
      default: "primary"
    }
  },
  emits: {
    "update:asset": value => true,
    "update:amount": value => true
  },
  setup(props, _ref) {
    let {
      emit,
      slots
    } = _ref;
    const dialog = ref(false);
    const persets = computed(() => {
      return {
        reverse: true,
        type: "number",
        precision: 8
      };
    });
    const classes = computed(() => {
      return ["f-asset-amount-input", {
        "f-asset-amount-input--filled": props.fullfilled
      }];
    });
    return () => _createVNode(FInput, _mergeProps(persets.value, {
      "modelValue": props.amount,
      "class": classes.value,
      "onUpdate:modelValue": v => emit("update:amount", v)
    }), {
      ...slots,
      "prepend-inner": () => _createVNode(FAssetSelect, {
        "dialog": dialog.value,
        "onUpdate:dialog": $event => dialog.value = $event,
        "outlined": false,
        "asset": props.asset,
        "assets": props.assets,
        "themeColor": props.themeColor,
        "onUpdate:asset": v => emit("update:asset", v)
      }, null)
    });
  }
});