import { createVNode as _createVNode } from "vue";
export var FIconImport4PBold = function FIconImport4PBold() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "16",
    "height": "16",
    "viewBox": "0 0 16 16",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "d": "M9 3C9 2.44772 8.55228 2 8 2C7.44772 2 7 2.44772 7 3V8.15685L5.17157 6.32843C4.78105 5.9379 4.14788 5.9379 3.75736 6.32843C3.36684 6.71895 3.36684 7.35212 3.75736 7.74264L7.29289 11.2782C7.68342 11.6687 8.31658 11.6687 8.70711 11.2782L12.2426 7.74264C12.6332 7.35212 12.6332 6.71895 12.2426 6.32843C11.8521 5.9379 11.219 5.9379 10.8284 6.32843L9 8.15685V3Z",
    "fill": "currentColor"
  }, null), _createVNode("path", {
    "d": "M2.5 13C2.5 12.4477 2.94772 12 3.5 12H12.5C13.0523 12 13.5 12.4477 13.5 13C13.5 13.5523 13.0523 14 12.5 14H3.5C2.94772 14 2.5 13.5523 2.5 13Z",
    "fill": "currentColor"
  }, null)]);
};