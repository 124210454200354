import { isVNode as _isVNode, createVNode as _createVNode } from "vue";
import { defineComponent, ref, watch } from "vue";
import { useLocale } from "vuetify";
import { VOverlay } from "vuetify/components";
import { VBtn } from "vuetify/components";
import "./FPayingModal.css";
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
export const FPayingModal = defineComponent({
  name: "FPayingModal",
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ""
    }
  },
  emits: {
    "update:modelValue": v => true,
    cancel: () => true
  },
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const {
      t
    } = useLocale();
    const timer = ref(null);
    const lasting = ref(false);
    const handleModalChange = value => {
      lasting.value = false;
      if (timer.value) {
        clearTimeout(timer.value);
      }
      if (value) {
        timer.value = setTimeout(() => {
          lasting.value = true;
        }, 30000);
      }
    };
    watch(() => props.modelValue, handleModalChange);
    return () => {
      let _slot;
      return _createVNode(VOverlay, {
        "modelValue": props.modelValue,
        "class": "f-payment-loading d-flex align-center justify-center",
        "persistent": true,
        "onUpdate:modelValue": v => emit("update:modelValue", v)
      }, {
        default: () => [_createVNode("div", {
          "class": "f-spinner__wrapper"
        }, [Array.from({
          length: 4
        }).map(() => _createVNode("span", {
          "class": "f-spinner__rect"
        }, null))]), _createVNode("div", {
          "class": "f-payment__hint text-center text-subtitle-2"
        }, [lasting.value ? t("$vuetify.uikit.lasting_tip") : props.text || t("$vuetify.uikit.checking_payment")]), _createVNode("div", {
          "class": "f-payment__actions"
        }, [_createVNode(VBtn, {
          "rounded": "pill",
          "variant": "outlined",
          "color": "greyscale_7",
          "onClick": () => emit("cancel")
        }, _isSlot(_slot = t("$vuetify.uikit.cancel")) ? _slot : {
          default: () => [_slot]
        })])]
      });
    };
  }
});