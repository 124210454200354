import { createVNode, nextTick, render, getCurrentInstance } from "vue";
import { FMessageModal } from "../components/FMessageModal/FMessageModal";
export function useModal() {
  const instance = getCurrentInstance();
  return instance.appContext.config.globalProperties.$uikit.modal;
}
function install(app) {
  let instance = null;
  const show = options => {
    if (instance) {
      instance.component.exposed.close();
    }
    nextTick(() => {
      const appendTo = document.querySelector(options.container || "[data-v-app]");
      const container = document.createElement("div");
      const vnode = createVNode(FMessageModal, {
        ...options,
        attach: container,
        onDestroy: () => {
          render(null, container);
          instance = null;
          appendTo?.removeChild(container);
        }
      });
      vnode.appContext = app._context;
      render(vnode, container);
      appendTo?.appendChild(container);
      instance = vnode;
      instance.component.exposed.show();
    });
  };
  const clear = () => {
    if (!instance) return;
    instance.component.exposed.close();
  };
  const modal = {
    show,
    clear
  };
  const properties = app.config.globalProperties;
  properties.$uikit = properties.$uikit || {};
  properties.$uikit.modal = modal;
}
export function Modal() {}
Modal.install = install;