import { getCurrentInstance } from "vue";
export function isMVM(channel) {
  return channel === "metamask" || channel === "walletconnect" || channel === "onekey";
}
export function useAuth(app) {
  return app?._context.config.globalProperties.$uikit?.auth;
}
export function useToast(app) {
  return app?._context.config.globalProperties.$uikit?.toast;
}
export function usePayment(app) {
  return app?._context.config.globalProperties.$uikit?.payment;
}
export function usePassport(app) {
  if (app) {
    return app?._context.config.globalProperties.$passport;
  } else {
    const instance = getCurrentInstance();
    return instance.appContext.config.globalProperties.$passport;
  }
}