import { isVNode as _isVNode, createVNode as _createVNode } from "vue";
import { defineComponent } from "vue";
import { useDisplay, useLocale } from "vuetify";
import { isDesktop } from "@foxone/utils/helper";
import { FButton } from "../FButton";
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
export const FAuthOnekeyInstall = defineComponent({
  name: "FAuthOnekeyInstall",
  inheritAttrs: false,
  setup() {
    const {
      smAndDown
    } = useDisplay();
    const {
      t
    } = useLocale();
    const handleInstall = () => {
      const url = "https://onekey.so/download?client=browserExtension";
      window.open(url);
    };
    return () => {
      let _slot;
      return _createVNode("div", {
        "class": ["f-auth-onekey", "f-auth-step2", {
          "f-auth-step2--small": smAndDown.value,
          "f-auth-onekey--mobile": !isDesktop
        }]
      }, [_createVNode("div", {
        "class": "f-auth-step2__left"
      }, null), _createVNode("div", {
        "class": "f-auth-step2__right"
      }, [_createVNode("div", {
        "class": "f-auth-step2__title",
        "innerHTML": isDesktop() ? t("$vuetify.uikit.onekey_not_installed") : t("$vuetify.uikit.onekey_not_installed_2")
      }, null), _createVNode("div", {
        "class": "f-auth-step2__subtitle",
        "innerHTML": isDesktop() ? t("$vuetify.uikit.onekey_introduction") : t("$vuetify.uikit.onekey_introduction_2")
      }, null), _createVNode(FButton, {
        "color": "greyscale_1",
        "onClick": handleInstall
      }, _isSlot(_slot = t("$vuetify.uikit.install")) ? _slot : {
        default: () => [_slot]
      })])]);
    };
  }
});