export const IconMessage = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.75871 2H16.2413C17.0463 1.99999 17.7106 1.99998 18.2518 2.04419C18.8139 2.09012 19.3306 2.18868 19.816 2.43597C20.5686 2.81947 21.1805 3.43139 21.564 4.18404C21.8113 4.66937 21.9099 5.18608 21.9558 5.74817C22 6.28936 22 6.95372 22 7.75868V13.2413C22 14.0463 22 14.7106 21.9558 15.2518C21.9099 15.8139 21.8113 16.3306 21.564 16.816C21.1805 17.5686 20.5686 18.1805 19.816 18.564C19.3306 18.8113 18.8139 18.9099 18.2518 18.9558C17.7106 19 17.0463 19 16.2413 19H9.68376C9.01965 19 8.82625 19.0047 8.65027 19.0408C8.47374 19.0771 8.30292 19.137 8.14245 19.219C7.98246 19.3007 7.8285 19.4178 7.30992 19.8327L4.89678 21.7632C4.7131 21.9102 4.52588 22.0601 4.36128 22.1689C4.20385 22.273 3.89861 22.4593 3.50163 22.4597C3.04482 22.4602 2.61268 22.2525 2.32769 21.8955C2.08004 21.5852 2.03483 21.2305 2.01776 21.0425C1.99991 20.846 1.99996 20.6062 2 20.371L2.00001 7.7587C2 6.95373 1.99999 6.28937 2.0442 5.74818C2.09013 5.18608 2.18869 4.66937 2.43598 4.18404C2.81948 3.43139 3.4314 2.81947 4.18405 2.43597C4.66938 2.18868 5.18609 2.09012 5.74818 2.04419C6.28938 1.99998 6.95374 1.99999 7.75871 2Z"
        fill="currentColor"
      />
    </svg>
  );
};
