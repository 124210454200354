import { createVNode as _createVNode, mergeProps as _mergeProps } from "vue";
import "./FAuthMethodModal.css";
import { defineComponent, ref, watch } from "vue";
import { useLocale } from "vuetify";
import { isMixin } from "@foxone/utils/mixin";
import { FModal } from "../FModal";
import { FAuthStep1 } from "./FAuthStep1";
import { FAuthStep2 } from "./FAuthStep2";
import authorize from "../../utils/authorize";
export const FAuthMethodModal = defineComponent({
  name: "FAuthMethodModal",
  inheritAttrs: false,
  props: {
    authMethods: {
      type: Array,
      default: () => ["mixin"]
    },
    authMethodState: {
      type: Object,
      default: () => ({})
    },
    clientId: {
      type: String,
      default: ""
    },
    scope: {
      type: String,
      default: ""
    },
    isFiresbox: {
      type: Boolean,
      default: false
    },
    hosts: {
      type: Array,
      default: () => []
    },
    pkce: {
      type: Boolean,
      default: false
    }
  },
  emits: {
    auth: v => true,
    error: v => true,
    destroy: () => true
  },
  setup(props, _ref) {
    let {
      emit,
      expose
    } = _ref;
    const {
      t
    } = useLocale();
    const dialog = ref(false);
    const step = ref(1);
    const client = ref(null);
    const method = ref("");
    const content = () => _createVNode("div", {
      "class": "f-auth-methods__wrapper"
    }, [step.value === 1 ? _createVNode(FAuthStep1, _mergeProps(props, {
      "step": step.value,
      "onUpdate:step": $event => step.value = $event,
      "method": method.value,
      "onUpdate:method": $event => method.value = $event,
      "onClose": close,
      "onAuth": v => emit("auth", v),
      "onError": e => emit("error", e)
    }), null) : _createVNode(FAuthStep2, _mergeProps(props, {
      "step": step.value,
      "onUpdate:step": $event => step.value = $event,
      "method": method.value,
      "onUpdate:method": $event => method.value = $event,
      "onClose": close,
      "onAuth": v => emit("auth", v),
      "onError": e => emit("error", e)
    }), null)]);
    const close = () => {
      dialog.value = false;
    };
    const handleDialogChange = value => {
      if (!value) {
        step.value = 1;
        method.value = "";
        dialog.value = false;
        close();
        emit("destroy");
        client.value?.disconnect();
      }
    };
    const handleModalValueChange = value => {
      dialog.value = value;
      emit("error", new Error("Cancelled"));
    };
    watch(() => dialog.value, handleDialogChange);
    const show = () => {
      if (isMixin()) {
        client.value = authorize({
          clientId: props.clientId,
          scope: props.scope,
          pkce: props.pkce
        }, props.isFiresbox, props.hosts, {
          onError: error => emit("error", error),
          onSuccess: data => {
            if (props.pkce) {
              emit("auth", {
                type: "mixin",
                token: data
              });
            } else {
              emit("auth", {
                type: "mixin",
                code: data
              });
            }
          }
        });
      } else {
        dialog.value = true;
      }
    };
    expose({
      show,
      close
    });
    return () => _createVNode(FModal, {
      "modelValue": dialog.value,
      "title": step.value === 1 ? t("$vuetify.uikit.connect_wallet") : "",
      "max-width": "628",
      "onUpdate:modelValue": handleModalValueChange
    }, {
      default: () => content()
    });
  }
});