import { createVNode as _createVNode, mergeProps as _mergeProps } from "vue";
import { defineComponent, ref, watch, computed } from "vue";
import { useLocale, useDisplay } from "vuetify";
import { FModal } from "../FModal";
import { FButton } from "../FButton";
import { FRender } from "../FRender";
import "./FMessageModal.css";
export const FMessageModal = defineComponent({
  name: "FMessageModal",
  props: {
    title: {
      type: [String, Object],
      default: ""
    },
    text: {
      type: [String, Object],
      default: ""
    },
    cancel: {
      type: Object,
      default: () => ({})
    },
    confirm: {
      type: Object,
      default: () => ({})
    },
    actions: {
      type: Object
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    width: {
      type: [String, Number],
      default: 420
    }
  },
  emits: {
    destroy: () => true
  },
  setup(props, _ref) {
    let {
      emit,
      expose
    } = _ref;
    const {
      t
    } = useLocale();
    const {
      smAndDown
    } = useDisplay();
    const dialog = ref(false);
    const width = computed(() => smAndDown.value ? '100%' : props.width);
    const show = () => dialog.value = true;
    const close = () => dialog.value = false;
    const handleDialogChange = value => {
      if (!value) {
        emit("destroy");
      }
    };
    watch(() => dialog.value, handleDialogChange);
    expose({
      show,
      close
    });
    const genAction = action => {
      if (!action.show) {
        return null;
      }
      return _createVNode(FButton, _mergeProps(action.props || {}, {
        "class": action.classes || "",
        "onClick": () => {
          dialog.value = false;
          action.callback && action.callback();
        }
      }), {
        default: () => [action.text]
      });
    };
    return () => _createVNode(FModal, {
      "modelValue": dialog.value,
      "onUpdate:modelValue": $event => dialog.value = $event,
      "title": _createVNode("div", {
        "class": "f-msg-modal__title"
      }, [_createVNode(FRender, {
        "content": props.title
      }, null)]),
      "width": width.value,
      "class": "f-msg-modal"
    }, {
      default: () => [_createVNode("div", {
        "class": "f-msg-modal__body"
      }, [_createVNode(FRender, {
        "content": props.text
      }, null), !props.hideActions && _createVNode("div", {
        "class": "f-msg-modal__actions"
      }, [props.actions || [genAction({
        show: true,
        text: t("$vuetify.uikit.cancel"),
        props: {
          variant: "plain"
        },
        ...(props?.cancel || {})
      }), genAction({
        show: true,
        text: t("$vuetify.uikit.continue"),
        props: {
          color: "greyscale_1"
        },
        ...(props?.confirm || {})
      })]])])]
    });
  }
});