import { createVNode, render, getCurrentInstance, nextTick } from "vue";
import { FToast, types } from "../components/FToast/FToast";
export function useToast() {
  const instance = getCurrentInstance();
  return instance.appContext.config.globalProperties.$uikit.toast;
}
function install(app, globalOptions) {
  let instance = null;
  const show = function () {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (instance) {
      instance.component.exposed.close();
    }
    nextTick(() => {
      const mergedOptions = {
        ...globalOptions,
        ...options
      };
      const appendTo = document.querySelector(mergedOptions.container || "[data-v-app]");
      const container = document.createElement("div");
      const vnode = createVNode(FToast, {
        ...mergedOptions,
        attach: container,
        onDestroy: () => {
          render(null, container);
          instance = null;
          appendTo?.removeChild(container);
        }
      });
      vnode.appContext = app._context;
      render(vnode, container);
      appendTo?.appendChild(container);
      instance = vnode;
      instance.component.exposed.show();
    });
  };
  const clear = () => {
    if (!instance) return;
    instance.component.exposed.close();
  };
  const toast = {
    show,
    clear
  };
  const properties = app.config.globalProperties;
  types.forEach(type => {
    toast[type] = options => show({
      type,
      ...options
    });
  });
  properties.$uikit = properties.$uikit || {};
  properties.$uikit.toast = toast;
}
export function Toast() {}
Toast.install = install;