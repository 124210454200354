import { isMVM } from "./helper";
export default async function (id, state) {
  if (state.channel === "mixin") {
    return await state.mixin.getAsset(id);
  }
  if (state.channel === "fennec") {
    return await state.fennec.ctx?.wallet.getAsset(id);
  }
  if (isMVM(state.channel)) {
    return await state.mvm?.getAsset(id);
  }
  return null;
}