import { isVNode as _isVNode, createVNode as _createVNode } from "vue";
import { defineComponent, computed } from "vue";
import { FAssetItem } from "./FAssetItem";
import { VList } from "vuetify/components";
import "./FAssetList.css";
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
export const FAssetList = defineComponent({
  name: "FAssetList",
  props: {
    asset: {
      type: Object,
      default: null
    },
    assets: {
      type: Array,
      default: () => []
    }
  },
  emits: {
    select: asset => true
  },
  setup(props, _ref) {
    let {
      emit
    } = _ref;
    const current = computed(() => [props?.asset?.id] ?? undefined);
    return () => {
      let _slot;
      return _createVNode(VList, {
        "selected": current.value,
        "class": ["f-asset-select__list"]
      }, _isSlot(_slot = props.assets.map(asset => _createVNode(FAssetItem, {
        "asset": asset,
        "current": props?.asset?.id,
        "key": asset.id,
        "onClick": () => emit("select", asset)
      }, null))) ? _slot : {
        default: () => [_slot]
      });
    };
  }
});