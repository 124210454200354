import { createVNode as _createVNode } from "vue";
import { computed, defineComponent } from "vue";
import "./FInfoGrid.css";
export const FInfoGrid = defineComponent({
  name: "FInfoGrid",
  props: {
    reverse: Boolean,
    list: Boolean
  },
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    const classes = computed(() => {
      return ["f-info-grid", {
        "f-info-grid--reverse": props.reverse,
        "f-info-grid--list": props.list
      }];
    });
    return () => _createVNode("div", {
      "class": classes.value
    }, [slots.default?.()]);
  }
});