import { mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment } from "vue";
import { defineComponent } from "vue";
import { VBottomNavigation, VIcon } from "vuetify/components";
import { FButton } from "../FButton";
import "./FBottomNav.css";
export const FBottomNav = defineComponent({
  name: "FBottomNav",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    const presets = {
      height: 66,
      elevation: 0,
      mandatory: true
    };
    const content = () => _createVNode(_Fragment, null, [props.items.map(item => _createVNode(FButton, {
      "value": item.value,
      "to": item.to,
      "exact": item.exact,
      "active": item.active,
      "class": "f-bottom-nav__item"
    }, {
      default: () => [_createVNode(VIcon, {
        "class": "f-bottom-nav__icon"
      }, {
        default: () => [item.icon]
      }), item.text && _createVNode("span", {
        "class": "f-bottom-nav__text"
      }, [item.text])]
    }))]);
    return () => _createVNode(VBottomNavigation, _mergeProps({
      "class": "f-bottom-nav"
    }, presets), {
      default: () => {
        return slots.default?.() || content();
      }
    });
  }
});