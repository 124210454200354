import { createTextVNode as _createTextVNode, Fragment as _Fragment, createVNode as _createVNode } from "vue";
import { defineComponent, withModifiers } from "vue";
import { useLocale } from "vuetify";
import { VIcon, VSpacer, VMessages } from "vuetify/components";
import { FButton } from "../FButton";
import "./FAssetInputTools.css";
export const FAssetInputTools = defineComponent({
  name: "FAssetInputTools",
  props: {
    balance: {
      type: [String, Number],
      default: ""
    },
    balanceLabel: {
      type: String,
      default: ""
    },
    fiatAmount: {
      type: String,
      default: ""
    },
    fillable: {
      type: Boolean,
      default: true
    },
    messages: {
      type: Array,
      default: () => []
    },
    connected: Boolean,
    disabled: Boolean
  },
  emits: {
    connect: () => true,
    fill: () => true
  },
  setup(props, _ref) {
    let {
      slots,
      emit
    } = _ref;
    const {
      t
    } = useLocale();
    const handleConnect = () => emit("connect");
    const handleFill = () => props.fillable && emit("fill");
    return () => _createVNode("div", {
      "class": "f-asset-input-tools"
    }, [slots.left?.() || _createVNode("div", {
      "class": "f-asset-input-tools__left"
    }, [!props.connected ? _createVNode(FButton, {
      "variant": "text",
      "size": "x-small",
      "onClick": handleConnect,
      "class": "f-asset-input-tools__connect-btn"
    }, {
      default: () => [_createVNode("span", null, [t("$vuetify.uikit.connect_wallet")])]
    }) : _createVNode(_Fragment, null, [_createVNode("div", {
      "class": "f-asset-input-tools__balance"
    }, [_createVNode("span", {
      "class": "f-asset-input-tools__balance-label"
    }, [props.balanceLabel || t("$vuetify.uikit.balance_short")]), _createVNode("span", {
      "class": "f-asset-input-tools__balance-value",
      "onClick": withModifiers(handleFill, ["stop"])
    }, [props.balance]), props.fillable && _createVNode(VIcon, {
      "size": "12",
      "color": "greyscale_3",
      "onClick": withModifiers(handleFill, ["stop"])
    }, {
      default: () => [_createTextVNode("$fill")]
    })]), slots["append-left"]?.()])]), _createVNode(VSpacer, null, null), slots.right?.() || props.messages.length > 0 ? _createVNode(VMessages, {
      "messages": props.messages,
      "active": true,
      "color": "error"
    }, null) : _createVNode("span", {
      "class": "fiat-amount"
    }, [props.fiatAmount])]);
  }
});