import { createVNode as _createVNode, mergeProps as _mergeProps } from "vue";
import { defineComponent, computed } from "vue";
import { VSwitch } from "vuetify/components";
import "./FSwitch.css";
export const FSwitch = defineComponent({
  name: "FSwitch",
  props: {
    outlined: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const preset = {
      inset: true,
      ripple: false,
      hideDetails: true
    };
    const classes = computed(() => {
      return ["f-switch", {
        "f-switch--outlined": props.outlined
      }];
    });
    return () => _createVNode(VSwitch, _mergeProps(preset, {
      "class": classes.value
    }), null);
  }
});