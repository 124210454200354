import { createVNode as _createVNode } from "vue";
import { computed, defineComponent } from "vue";
import "./FFiatDivision.css";
export const FFiatDivision = defineComponent({
  name: "FFiatDivision",
  props: {
    parts: {
      type: [Array, String],
      default: () => []
    }
  },
  setup(props) {
    const html = computed(() => {
      return typeof props.parts === "string" ? props.parts : props.parts.reduce((str, part) => {
        if (part.type === "currency") {
          return `${str}<span class="f-fiat-division__symbol">${part.value}</span>`;
        }
        return `${str}${part.value}`;
      }, "");
    });
    return () => _createVNode("div", {
      "innerHTML": html.value,
      "class": "f-fiat-division"
    }, null);
  }
});