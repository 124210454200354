import { createVNode as _createVNode, resolveDirective as _resolveDirective } from "vue";
import { defineComponent, toRef } from "vue";
import { VBtnGroup } from "vuetify/components";
import { makePositionProps, usePosition } from "vuetify/lib/composables/position.mjs";
import { useBackgroundColor } from "vuetify/lib/composables/color.mjs";
import "./FActionBar.css";
export const FActionBar = defineComponent({
  name: "FActionBar",
  props: {
    color: String,
    ...makePositionProps()
  },
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    const {
      positionClasses
    } = usePosition(props);
    const {
      backgroundColorClasses,
      backgroundColorStyles
    } = useBackgroundColor(toRef(props, "color"));
    return () => _createVNode(VBtnGroup, {
      "class": ["f-action-bar", positionClasses.value, backgroundColorClasses.value],
      "style": [backgroundColorStyles.value]
    }, slots);
  }
});