export default {
  talkee: {
    sort_newest: "Newest",
    sort_oldest: "Oldest",
    sort_score: "Score",
    logout: "Logout",
    login: "Login",
    submit: "Submit",
    comment_placeholder: "Leave your comment",
    reply: "Reply",
    error_hint: "Something Error Happend",
    load_more: "Show More",
    no_more_comments: "No More Comments",
    view_in_explorer: "View in Explorer",
    user_info: "User Info",
    airdrop: "Send Airdrop",
    reward: "Send Tips",
    select_asset: "Select Asset",
    memo: "Memo",
    select_strategy: "Strategy",
    input_topn: "Number of Airdrops",
    start_chat: "Start chat",
    input_integer: "Input the \"N\"",
    input_amount: "Total amount",
    asset_required: "Asset is required",
    amount_invalid: "Amount is invalid",
    n_required: "Number of airdrop is required",
    amount_need_integer: "Number should be integer",
    reward_details: "Airdrop Details",
    airdrop_details: "Airdrop Details",
    chat_room: "Chat Room",
    please_login: "Please login",
    top_n: "Top N",
    avg: "Avarage",
    top_n_hint: "Only top N comments with highest score will share the airdrop",
    avg_hint: "Every comment will share the airdrop",
    total_received: "Total received",
    details: "Details",
    send_tips: "Send Tips",
    send_airdrop: "Send Airdrop",
  },
};
