import { createVNode as _createVNode } from "vue";
import { defineComponent } from "vue";
import { VImg } from "vuetify/components";
import "./FAssetLogo.css";
export const FAssetLogo = defineComponent({
  name: "FAssetLogo",
  props: {
    asset: {
      type: Object,
      default: null
    },
    size: {
      type: [String, Number],
      default: 64
    },
    chainSize: {
      type: [String, Number],
      default: 18
    }
  },
  setup(props) {
    return () => _createVNode("div", {
      "class": "f-asset-logo"
    }, [_createVNode(VImg, {
      "width": props.size,
      "src": props.asset?.logo
    }, null), props.asset?.chainLogo && _createVNode(VImg, {
      "class": "f-asset-logo__chain",
      "width": props.chainSize,
      "src": props.asset?.chainLogo
    }, null)]);
  }
});