import { createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { VIcon } from "vuetify/components";
import { FInput } from "../FInput";
import "./FSearchInput.css";
export const FSearchInput = defineComponent({
  name: "FSearchInput",
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    const {
      t
    } = useLocale();
    return () => _createVNode(FInput, {
      "hide-details": true,
      "class": "f-search-input",
      "placeholder": t("$vuetify.uikit.search")
    }, {
      ...slots,
      "prepend-inner": () => _createVNode("span", {
        "class": "f-search-input-prepend"
      }, [_createVNode(VIcon, {
        "size": 16,
        "color": "greyscale_3"
      }, {
        default: () => [_createTextVNode("$search")]
      })])
    });
  }
});