import { createVNode as _createVNode } from "vue";
import { defineComponent } from "vue";
import { FTooltip } from "../FTooltip";
import "./FInfoGridItem.css";
export const FInfoGridItem = defineComponent({
  name: "FInfoGridItem",
  props: {
    title: String,
    value: String,
    hint: String,
    unit: String
  },
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    return () => _createVNode("div", {
      "class": "f-info-grid-item"
    }, [_createVNode("div", {
      "class": "f-info-grid-item__title"
    }, [slots.title?.() ?? props.title, _createVNode(FTooltip, {
      "hint": props.hint
    }, null)]), _createVNode("div", {
      "class": "f-info-grid-item__value"
    }, [_createVNode("span", null, [slots.value?.() ?? props.value]), _createVNode("span", null, [props.unit])])]);
  }
});