import { createVNode as _createVNode, mergeProps as _mergeProps } from "vue";
import { computed, defineComponent, ref, withModifiers } from "vue";
import { useLocale } from "vuetify";
import { FModal } from "../FModal";
import { FAssetList } from "./FAssetList";
import { FAssetSelectField } from "./FAssetSelectField";
import { FSearchInput } from "../FSearchInput";
import { filterAssets } from "../../utils";
import "./FAssetSelect.css";
export const FAssetSelect = defineComponent({
  name: "FAssetSelect",
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    asset: {
      type: Object,
      default: null
    },
    assets: {
      type: Array,
      default: () => []
    },
    themeColor: {
      type: String,
      default: "primary"
    }
  },
  emits: {
    "update:asset": value => true,
    "update:dialog": value => true
  },
  setup(props, _ref) {
    let {
      emit,
      attrs
    } = _ref;
    const {
      t
    } = useLocale();
    const filter = ref("");
    const filteredAssets = computed(() => filterAssets(props.assets, filter.value));
    const empty = computed(() => filteredAssets.value.length === 0);
    const handleSelect = v => {
      emit("update:asset", v);
      emit("update:dialog", false);
    };
    const handleClear = () => filter.value = "";
    return () => _createVNode(FModal, {
      "title": t("$vuetify.uikit.select_asset"),
      "modelValue": props.dialog,
      "desktop": "menu",
      "close-on-content-click": false,
      "onUpdate:modelValue": v => emit("update:dialog", v)
    }, {
      activator: _ref2 => {
        let {
          isActive,
          props: {
            onClick
          }
        } = _ref2;
        return _createVNode(FAssetSelectField, _mergeProps({
          "class": [{
            "f-asset-field--active": isActive
          }],
          "asset": props.asset,
          "onClick": withModifiers(onClick, ["stop"]),
          "onMousedown": withModifiers(() => {}, ["stop"])
        }, attrs), null);
      },
      default: () => _createVNode("div", {
        "class": "f-asset-select"
      }, [_createVNode("div", {
        "class": "f-asset-select__search"
      }, [_createVNode(FSearchInput, {
        "modelValue": filter.value,
        "onUpdate:modelValue": $event => filter.value = $event,
        "variant": "outlined",
        "clearable": true,
        "color": props.themeColor,
        "onClick:clear": handleClear
      }, null)]), !empty.value ? _createVNode(FAssetList, {
        "asset": props.asset,
        "assets": filteredAssets.value,
        "onSelect": handleSelect
      }, null) : _createVNode("div", {
        "class": "f-asset-select__search--empty"
      }, [_createVNode("img", {
        "src": "https://static.fox.one/image/empty_placeholder.svg"
      }, null), _createVNode("p", null, [t("$vuetify.uikit.no_results")])])])
    });
  }
});