import { createVNode as _createVNode } from "vue";
export var FIconArrowRight4P = function FIconArrowRight4P() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "17",
    "height": "17",
    "viewBox": "0 0 17 17",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "d": "M8.707 4.25736L12.9496 8.5L8.707 12.7426",
    "stroke": "currentColor",
    "stroke-width": "1.5",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null), _createVNode("path", {
    "d": "M12.5 8.5L4 8.5",
    "stroke": "currentColor",
    "stroke-width": "1.5",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null)]);
};