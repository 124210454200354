import { createVNode as _createVNode } from "vue";
import { defineComponent } from "vue";
import { FLoading } from "./FLoading";
import { VOverlay } from "vuetify/components";
export const FFullscreenLoading = defineComponent({
  name: "FFullscreenLoading",
  setup(props, _ref) {
    let {
      attrs
    } = _ref;
    return () => _createVNode(VOverlay, {
      "persistent": true,
      "class": "f-fullscreen-loading"
    }, {
      default: () => [_createVNode(FLoading, attrs, null)]
    });
  }
});