import { createVNode as _createVNode } from "vue";
import { defineComponent } from "vue";
import InfiniteLoading from "v3-infinite-loading";
import { useLocale } from "vuetify";
import { FLoading } from "../FLoading";
import "./FInfiniteLoad.css";
export const FInfiniteLoad = defineComponent({
  name: "FInfiniteLoad",
  props: {
    completeText: {
      type: String,
      default: ""
    },
    errorText: {
      type: String,
      default: ""
    }
  },
  setup(props, _ref) {
    let {
      slots
    } = _ref;
    const {
      t
    } = useLocale();
    return () => _createVNode(InfiniteLoading, null, {
      spinner: () => _createVNode(FLoading, {
        "size": "16"
      }, null),
      complete: () => _createVNode("div", {
        "class": "f-infinited-load__hint"
      }, [_createVNode("span", null, [props.completeText || t("$vuetify.uikit.infinite_complete")])]),
      error: _ref2 => {
        let {
          retry
        } = _ref2;
        return _createVNode("div", {
          "class": "f-infinited-load__hint"
        }, [_createVNode("span", null, [props.errorText || t("$vuetify.uikit.infinite_error")]), _createVNode("span", {
          "onClick": retry,
          "class": "f-infinited-load__retry"
        }, [t("$vuetify.uikit.retry")])]);
      },
      ...slots
    });
  }
});