import { isVNode as _isVNode, mergeProps as _mergeProps, createVNode as _createVNode } from "vue";
import { computed, defineComponent, unref } from "vue";
import { VBtnToggle } from "vuetify/components";
import { useColor } from "vuetify/lib/composables/color.mjs";
import { FButton } from "../FButton";
import "./FSegmentControl.css";
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
export const FSegmentControl = defineComponent({
  name: "FSegmentControl",
  props: {
    grow: Boolean,
    bgColor: {
      type: String,
      default: "greyscale_6"
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const {
      colorClasses,
      colorStyles
    } = useColor(computed(() => ({
      background: unref(props.bgColor)
    })));
    const presets = {
      color: "greyscale_1",
      mandatory: true
    };
    return () => {
      let _slot;
      return _createVNode(VBtnToggle, _mergeProps({
        "class": ["f-segment-control", colorClasses.value, {
          "f-segment-control--grow": props.grow
        }],
        "style": [colorStyles.value]
      }, presets), _isSlot(_slot = props.items.map(item => _createVNode(FButton, item, {
        default: () => [item.text]
      }))) ? _slot : {
        default: () => [_slot]
      });
    };
  }
});