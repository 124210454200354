import { createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
import { defineComponent } from "vue";
import { VIcon } from "vuetify/components";
export const FHintActivator = defineComponent({
  name: "FHintActivator",
  setup() {
    return () => _createVNode(VIcon, {
      "size": "12",
      "color": "greyscale_4",
      "class": "f-hint__activator"
    }, {
      default: () => [_createTextVNode("$help")]
    });
  }
});