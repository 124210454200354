import { createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import { defineComponent } from "vue";
import { VLazy, VIcon } from "vuetify/components";
import { FListItem } from "../FListItem";
import { FAssetLogo } from "../FAssetLogo";
import "./FAssetItem.css";
export const FAssetItem = defineComponent({
  name: "FAssetItem",
  props: {
    asset: {
      type: Object,
      default: null
    },
    current: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    return () => _createVNode(VLazy, {
      "min-height": 52
    }, {
      default: () => [_createVNode(FListItem, {
        "title": props.asset.symbol,
        "subtitle": props.asset.name,
        "value": props.asset.id,
        "class": "f-asset-item"
      }, {
        prepend: () => _createVNode(FAssetLogo, {
          "asset": props.asset,
          "size": "28",
          "chainSize": "12",
          "class": "f-asset-item__logo"
        }, null),
        append: () => props.current === props.asset?.id ? _createVNode(VIcon, {
          "size": 16
        }, {
          default: () => [_createTextVNode("$check")]
        }) : ""
      })]
    });
  }
});