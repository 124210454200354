export default {
  talkee: {
    "sort_newest": "最新",
    "sort_oldest": "最旧",
    "sort_score": "评分",
    "logout": "退出登录",
    "login": "登录",
    "submit": "提交",
    "comment_placeholder": "留下你的评论",
    "reply": "回复",
    "error_hint": "发生错误",
    "load_more": "显示更多",
    "no_more_comments": "没有更多评论了",
    "view_in_explorer": "在区块浏览器中查看",
    "user_info": "用户信息",
    "airdrop": "发送空投",
    "reward": "发送小费",
    "select_asset": "选择资产",
    "memo": "备注",
    "select_strategy": "策略",
    "input_topn": "空投人数",
    "start_chat": "开始聊天",
    "input_integer": "输入 N 的值",
    "input_amount": "总量",
    "asset_required": "资产必填",
    "amount_invalid": "金额无效",
    "n_required": "空投人数必填",
    "amount_need_integer": "数量必须为整数",
    "reward_details": "空投详情",
    "airdrop_details": "空投详情",
    "chat_room": "聊天室",
    "please_login": "请登录",
    "top_n": "空投给前 N",
    "avg": "平均空投",
    "top_n_hint": "只有前 N 名最高评分的评论将分享空投",
    "avg_hint": "每个评论都将分享空投",
    "total_received": "总接收量",
    "details": "详情",
    "send_tips": "发送小费",
    "send_airdrop": "发送空投"
  },
};
