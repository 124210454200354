export function convertMixinAsset(asset) {
  return {
    id: asset.asset_id,
    symbol: asset.symbol,
    logo: asset.icon_url,
    name: asset.name,
    chainLogo: asset.icon_url
  };
}
export function filterAssets(assets, filter) {
  return assets.filter(asset => {
    const name = (asset?.name || "").toLowerCase();
    const symbol = (asset?.symbol || "").toLowerCase();
    const filterStr = filter.toLowerCase();
    return name.includes(filterStr) || symbol.includes(filterStr);
  });
}
export function getBrowser() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf("firefox") > -1) {
    return "firefox";
  }
  if (ua.indexOf("chrome") > -1) {
    return "chrome";
  }
  return "others";
}