import { mergeProps as _mergeProps, resolveDirective as _resolveDirective, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue";
import { defineComponent, computed } from "vue";
import { VAppBar, VBtn, VIcon } from "vuetify/components";
import { useDisplay } from "vuetify";
import "./FAppBar.css";
export const FAppBar = defineComponent({
  name: "FAppBar",
  props: {
    back: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    },
    immersive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, _ref) {
    let {
      attrs,
      slots
    } = _ref;
    const {
      smAndDown
    } = useDisplay();
    const classes = computed(() => {
      return {
        "f-app-bar": true,
        "f-app-bar--immersive": props.immersive,
        "f-app-bar--center": props.center
      };
    });
    const presets = computed(() => {
      return {
        height: smAndDown.value ? 48 : 64,
        elevation: 0,
        app: true
      };
    });
    const back = () => _createVNode(VBtn, {
      "icon": true,
      "size": "24",
      "ripple": false
    }, {
      default: () => [_createVNode(VIcon, null, {
        default: () => [_createTextVNode("$back")]
      })]
    });
    const prepend = props.back ? back : slots.prepend;
    return () => _createVNode(VAppBar, _mergeProps({
      "class": classes.value
    }, presets.value, attrs), {
      ...slots,
      prepend
    });
  }
});